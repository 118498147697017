<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <!-- <div class="card-head">
                            <h3 class="card-title">Descarga</h3>
                        </div> -->
                        <div class="card-body p-0">
                            <downloadexcel
                                class            = "btn btn-primary m-4"
                                :fetch           = "download"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                Download Excel
                            </downloadexcel>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import downloadexcel from "vue-json-excel";


    export default {
        components: {
            downloadexcel,
        },
        data() {
            return {
                datos: {}
            }
        },
        methods: {
            async download() {
                // axios.get("/api/paricipante/download").then(({ data }) => (this.datos = data.data));
                const response = await axios.get('/api/paricipante/download');
                // console.log(response.data.data);
                return response.data.data;
            },
            startDownload(){
                // alert('show loading');
            },
            finishDownload(){
                // alert('hide loading');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>