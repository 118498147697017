<template>
    <div>
        <form v-if="isSave" @submit.prevent="createContacto()" >
            <input type="hidden" v-model='form.token' name="name"/>
            <div class="form-floating mb-3">
                <input class="form-control" v-rut v-model="rut" type="text" placeholder="Rut" required :class="{ 'is-invalid': form.errors.has('rut') }"/>
                <label for="name">Rut</label>
                <has-error :form="form" field="name"></has-error>
            </div>
            <div class="form-floating mb-3">
                <input class="form-control" v-model="form.nombre"  name="nombre" id="nombre" type="tel" placeholder="+56" required :class="{ 'is-invalid': form.errors.has('nombre') }" />
                <label for="nombre">Nombre</label>
                <has-error :form="form" field="nombre"></has-error>
            </div>
            <div class="form-floating mb-3">
                <input class="form-control" v-model="form.email" name="email" id="email" type="email" placeholder="name@example.com" required :class="{ 'is-invalid': form.errors.has('email') }" />
                <label for="email">Correo electrónico</label>
                <has-error :form="form" field="email"></has-error>
            </div>
            <div class="form-floating mb-3">
                <input class="form-control" v-model="form.telefono"  name="telefono" id="telefono" type="tel" placeholder="+56" required :class="{ 'is-invalid': form.errors.has('telefono') }" />
                <label for="telefono">Número telefónico (9 dígitos)</label>
                <has-error :form="form" field="telefono"></has-error>
            </div>
            

            <button  class="btn btn-primary btn-xl" :disabled='!isActivo' type="submit">
                Guardar
            </button>
        </form> 
        <div v-else>
            <h4 class="text-center text-secondary mb-0">
                Datos ya fueron actualizado, muchas gracias.
            </h4>
            <!-- Icon Divider-->
            <div class="divider-custom">
                <div class="divider-custom-line"></div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            token: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                isSave: true,
                isActivo: true,
                rut: '',
                form: new Form({
                    id : '',
                    rut : '',
                    nombre : '',
                    email: '',
                    telefono: '',
                    token: this.token
                })
            }
        },
        methods: {
            createContacto(){
                this.isActivo = false;
                this.form.post('/api/contacto')
                .then((response)=>{
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.isSave = false;
                    // this.loadUsers();

                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Se ha producido un error. Por favor, inténtelo de nuevo'
                    });
                    this.isActivo = true;
                })
            }
        },
        watch: {
            // whenever question changes, this function will run
            rut: function (newQuestion, oldQuestion) {
                this.form.rut = this.rut
            // this.debouncedGetAnswer()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>